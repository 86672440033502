import { Dialog } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Close from "../../assets/icons/cross";
import { useForm } from "react-hook-form";
import Input from "../../atoms/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { FreetrialSchema } from "./validation";
import Button from "../../atoms/Button";
import { freetrial } from "./service";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { CustomError } from "../../models/customError.interface";
import { fetchInvoices } from "../../pages/historyDetail/sagaAction";
import DropDown from "../../atoms/Dropdown";
import { subscriptionTypeOptions } from "./constants";
import { fetchOrganizationSubscription } from "../../pages/organization/sagaAction";

const Freetrial = ({
  handleClose,
  open,
  subscriptionData
}: any) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(FreetrialSchema),
  });
  const dispatch = useDispatch();
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };
  const onSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        subscriptionType:  subscriptionData?.productType,
        extensionTimePeriod: +watch("days"),
        stripeSubscriptionId:subscriptionData?.stripeSubscriptionId
      };
      await freetrial(payload );
      const subscriptionTypeLabel = subscriptionTypeOptions.find(
        (option) => option.value === subscriptionData.productType
      )?.label 
      handleShowToast(`${subscriptionTypeLabel} free trial started successfully!`, ToastType.success);
      setLoading(false);
      dispatch(fetchOrganizationSubscription(param.orgId))
      handleClose();  

    } catch (error: any) {
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setValue("days", "");
      reset();
    }
  }, [open]);

  return (
    <Dialog className="stepsDialog" onClose={handleClose} open={open}>
      <span role="link" onClick={handleClose} className="closeDialog">
        <Close />
      </span>
      <div className="dialogWrapper">
        <Input
          type="number"
          label="Enter Days*"
          register={register}
          name="days"
          errors={errors}
          placeholder="Enter no of days"
          control={control}
        ></Input>
        <div className="actions">
          <Button
            className="full primary"
            label={"Save"}
            action={handleSubmit(onSubmit)}
            disabled={loading }
            clicked={loading}
          />
        </div>
      </div>
    </Dialog>
  );
};
export default Freetrial;
