import { initialState } from "./reducer";

const getStateData = (state: any) => state["userDetail"] || initialState;

export const getUserDetailList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.userDetailList;
}


export const getLoadingUserDetail=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.loadingUserDetailList;
}

export const getUserListCount=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.userListCount;
}

export const getOrgUserRoleList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.orgUserRoleList;
}

export const getPendingUserDetailList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.pendingUserDetailList;
}


export const getLoadingPendingUserDetail=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.loadingPendingUserDetailList;
}