import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  workerList: [] as any[],
  workerListLoading: false,
  zoneList:[] as any[],
  zoneListLoading:false
};

const workerListSlice = createSlice({
  name: "workerList",
  initialState: initialState,
  reducers: {
    setWorkerList: (state, { payload }) => {
      return {
        ...state,
        workerList: payload,
      };
    },
    setWorkerListLoading: (state, { payload }) => {
      return {
        ...state,
        workerListLoading: payload,
      };
    },
    setZoneList: (state, { payload }) => {
      return {
        ...state,
        zoneList: payload,
      };
    },
    setZoneListLoading: (state, { payload }) => {
      return {
        ...state,
        zoneListLoading: payload,
      };
    },
  },
});

export const { setWorkerList, setWorkerListLoading,setZoneList,setZoneListLoading } = workerListSlice.actions;

export default workerListSlice.reducer;
