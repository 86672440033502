import { createAction } from "@reduxjs/toolkit";

export const fetchWorkersList = createAction(
  "WORKERS_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchZoneList = createAction(
  "ZONE_LIST",
  function prepare(data) {
    return { payload: data };
  }
);