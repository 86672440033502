import * as yup from "yup";

export const registerFormSchema = yup.object({
  name: yup.string().trim().required("Name is required").max(100,"Name must be at most 100 characters"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
    userRoleName:yup.string().required("Role name is required"),
    isPaidSeat:yup.boolean(),
  phone: yup
  .string()
  .nullable() 
  .transform((value) => {
    return value ? value.replaceAll("-", "") : value; 
  })
  .test('len', 'Phone number should have exactly 10 digits.', (value) => {
    return !value || /^[0-9]{10}$/.test(value); 
  }),
});
