import { createAction } from "@reduxjs/toolkit";

export const fetchUserDetailList = createAction(
  "UserDetail_list",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchOrgUserRoleList = createAction(
  "OrgUser_list",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchPendingUserDetailList = createAction(
  "Pending_UserDetail_list",
  function prepare(data) {
    return { payload: data };
  }
);