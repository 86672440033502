import React, { useCallback, useEffect, useState } from "react";
import Loader from "../../atoms/Loader";
import CustomTable from "../../molecules/table/CustomTable";
import { columns } from "../../organisms/leads/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeadListCount,
  getLeadListLoading,
  getLeadsList,
} from "../propertyDetail/selector";
import {
  copyToClipboard,
  followUpDateFormatter,
  formatPhoneNumber,
} from "../../utils/utils";
import {
  fetchLeadList,
  fetchPropertyDetail,
  propertyUpdateDetailsAction,
} from "../propertyDetail/sagaAction";
import Copy from "../../assets/icons/copy";
import { Tooltip } from "@mui/material";
import { LeadStatusMap } from "../../constant/leadStatus.constant";
import { useNavigate, useParams } from "react-router-dom";
import SearchReflation from "../../assets/icons/searchReflation";
import Button from "../../atoms/Button";
import { useForm } from "react-hook-form";
import { sendActivityLog } from "../../organisms/auth/service";
import { getProfileInfo } from "../profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { useDbounce } from "../../hooks/debounce.hook";

const LeadTable = ({  filter,
    handleNextPage,
    handleClearSearch }: any) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: "onSubmit",
  });
  let navigate = useNavigate();
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const loading = useSelector((state) => getLeadListLoading(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const LeadStatusOptions = Object.entries(LeadStatusMap).map(
    ([key, value]) => ({
      label: value,
      value: key,
    })
  );
  
  const leadList = useSelector((state) => getLeadsList(state));
  const totalCount = useSelector((state) => getLeadListCount(state));
  const handleStatusChange = (lead: any) => {
    dispatch(
      propertyUpdateDetailsAction({
        id: lead?.id,
        status: lead?.value,
        organisationId: orgId,
      })
    );
    let newTableData: any = leadList?.map((el: any) => {
      if (el.id === lead?.id) {
        return { ...el, updatedAt: new Date().toISOString().split("T")[0] };
      }
      return el;
    });
    logAuditData(`User has updated lead(${lead?.id}) status to ${lead?.value} of organisation ${orgId}`,`Lead status update`)
  };

  const generateCopy = (lead: any, type: string) => {
    const addressData = `${[
      lead.addressStreet,
      lead.addressCity,
      lead.addressState,
      lead.addressZip,
    ]}`;
    const phoneData = `${[
      lead?.phone?.number && formatPhoneNumber(lead?.phone?.number),
    ]}`;
    const summaryData = `${[lead?.latestPhoneAttempt?.summary]}`;
    const leadName = `${[
      lead?.owner[0]?.nameFirst + " " + lead?.owner[0]?.nameLast,
    ]}`;
    let text;
    if (type === "leadName") {
      text = leadName;
    } else if (type === "address") {
      text = addressData;
    } else if (type === "phone") {
      text = phoneData;
    } else {
      text = summaryData;
    }

    const copyTextData = `${[text]
      .filter((item) => typeof item === "string")
      .map((item) => item.trim())
      .filter(Boolean)
      .join(", ")}`;

    return (
      <Tooltip
        title={
          type === "leadName"
            ? "Copy Lead Name"
            : type === "address"
            ? "Copy Lead Address"
            : type === "phone"
            ? "Copy Phone Number"
            : "Copy Summary"
        }
      >
        <span
          role="link"
          className="copy-icon"
          onClick={(e: any) => {
            e.stopPropagation();
            copyToClipboard(copyTextData);
          }}
        >
          <Copy />
        </span>
      </Tooltip>
    );
  };

  const handleRowOnClick = (id: string | number) => {
    navigate(`/organisation/${orgId}/leads/${id}`);
    dispatch(fetchPropertyDetail({propertyId: id,orgId: orgId,}))
    logAuditData(`The user has selected lead ${id} of organisation ${orgId} and is navigating to the property detail page.`,`Lead selected`)
  };

  const handleDateChange = (lead: any) => {
    dispatch(
      propertyUpdateDetailsAction({
        id: lead?.id,
        followUpAt: lead?.followUpAt ? lead?.followUpAt?.split("T")[0] : null,
        organisationId: orgId,
      })
    );
    setValue(
      `follow_up_at_${lead?.id}`,
      lead?.followUpAt ? lead?.followUpAt.split("T")[0] : null
    );
    let newTableData: any = tableData.map((el: any) => {
      if (el.id === lead?.id) {
        return { ...el, updatedAtDate: followUpDateFormatter(new Date()) };
      }

      return el;
    });
    logAuditData(`User has updated lead(${lead?.id}) follow up date ${lead?.followUpAt?.split("T")[0]} of organisation ${orgId}`,`Follow up date update`)
    setTableData(newTableData);
  };

  const logAuditData = async (data:any,logType:any) => {
    const attributes = {
      action: data,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo.email,
      roleName: profileInfo.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      console.log(result);
      try {
        const res = await sendActivityLog({
          logType:logType,
          jsonData: result,
        });
        console.log(res);
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };

  const debounce = useDbounce(500, (filter:any) => {
    const payload = { index: filter?.index, size: filter?.size,organisationId:orgId,search: filter?.search,status:filter.leadStatus,campaignIds:filter.campaignIds };
    dispatch(fetchLeadList(payload));
  });
  
  const handleListData = useCallback(() => {
    const leadData = leadList?.map((lead: any) => {
      return {
        id: lead.id,
        phone: lead?.phone?.number
          ? formatPhoneNumber(lead?.phone?.number)
          : "-",
        phone_attempt_summary: lead?.latestPhoneAttempt?.summary || "-",
        lead: {
          title: `${lead?.owner?.[0]?.nameFirst ?? ""} ${
            lead?.owner?.[0]?.nameLast ?? ""
          }`,
          description: [
            lead.addressStreet,
            lead.addressCity,
            lead.addressState,
            lead.addressZip,
          ]
            .filter((item) => {
              if (typeof item === "string") {
                item = item.trim();
              }

              return item;
            })
            .filter(Boolean)
            .join(", "),
          copyAddress: generateCopy(lead, "address"),
          copyLeadName: generateCopy(lead, "leadName"),
        },
        status: lead?.status,
        followUpDate: followUpDateFormatter(
          lead.followUpAt ? new Date(lead?.followUpAt) : null
        ),
        handleOnClicked: () => handleRowOnClick(lead.id),
        updatedAtDate: followUpDateFormatter(
          lead.updatedAt ? new Date(lead?.updatedAt) : null
        ),
        isLoading: lead?.isLoading,
        copyPhone: generateCopy(lead, "phone"),
        copySummary: generateCopy(lead, "summary"),
        flag: false,
      };
    });
    setTableData(leadData ?? []);
  }, [leadList]);

  useEffect(() => {
    handleListData();
  }, [leadList]);

  useEffect(() => {
    debounce(filter)
  }, [orgId, filter]);

  return (
    <>
      {loading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <>
          <CustomTable
            rows={tableData}
            control={control}
            errors={errors}
            columns={columns}
            search={filter?.search}
            tableStriped
            hasMore={tableData?.length < totalCount}
            options={LeadStatusOptions}
            handleClickedPill={handleStatusChange}
            handleDateFn={handleDateChange}
            handleNextPage={handleNextPage}
            emptySearchMetaData={{
                icon: <SearchReflation />,
                title: "No results found",
                description:
                  "We couldn’t find any results that match your search.",
                button1: (
                  <Button
                    label="Clear search"
                    className="outline"
                    action={handleClearSearch}
                  />
                ),
              }}
              emptyTableMetaData={{
                icon: <SearchReflation />,
                title: "No results found",
              }}
          ></CustomTable>
        </>
      )}
    </>
  );
};

export default LeadTable;
