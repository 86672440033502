import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../molecules/table/CustomTable";
import { columns } from "./constant";
import { fetchWorkersList, fetchZoneList } from "./sagaAction";
import { useDispatch, useSelector } from "react-redux";
import { getworkerList, getworkerListLoading } from "./selector";
import Loader from "../../atoms/Loader";
import Button from "../../atoms/Button";
import Close from "../../assets/icons/cross";
import { Check } from "@mui/icons-material";
import ConfirmationModal from "../../components/confirmationModal/ConfirmationModal";
import { handleBot, handleIndividualWorkerBot } from "./service";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { CustomError } from "../../models/customError.interface";
import { setWorkerList } from "./reducer";
import { getProfileInfo } from "../profile/selector";
import { sendActivityLog } from "../../organisms/auth/service";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import styles from "./AIBotCalling.module.scss";
import HeroSection from "../../organisms/heroSection/HeroSection";
import { PermissionListEnum } from "../rolesAndPermission/enum";
import { Tooltip } from "@mui/material";
import ZoneModal from "../../components/zoneModal/ZoneModal";

const AIBotCalling = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const workersDetail = useSelector((state) => getworkerList(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [loading, setLoading] = useState(false);
  const [individualLoading, setIndividualLoading] = useState(false);
  const [individualBotId, setIndividualBotId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showZoneModal, setShowZoneModal] = useState(false);
  const workersDetailLoading = useSelector((state) =>
    getworkerListLoading(state)
  );

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const logAuditData = async (data:any,logType:any) => {
    const attributes = {
      action: data,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo.email,
      roleName: profileInfo.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      console.log(result);
      try {
        const res = await sendActivityLog({
          logType:logType,
          jsonData: result,
        });
        console.log(res);
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };

  const handleStopBot = async () => {
    try {
      setLoading(true);
      await handleBot({ newState: true });
      handleModal();
      handleShowToast("Emergency stop set successfully!", ToastType.success);
      
      dispatch(setWorkerList({
        ...workersDetail,
        emergencyStopped: true, 
        workers: workersDetail?.workers?.map((worker:any) => ({
          ...worker,
          emergencyStopped: true
        }))
      }));
      setLoading(false);
      logAuditData("Emergency stop set successfully","AI Bot stoped")
    } catch (err) {
      setLoading(false);
      handleShowToast(
        (err as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleZoneModal=()=>{
    setShowZoneModal(!showZoneModal)
  }
  const handleStartBot = async () => {
    try {
      setLoading(true);
      await handleBot({ newState: false });
      handleShowToast("Emergency stop lifted successfully!", ToastType.success);
      // dispatch(setWorkerList({...workersDetail,emergencyStopped:false}))
      dispatch(setWorkerList({
        ...workersDetail,
        emergencyStopped: false, 
        workers: workersDetail?.workers?.map((worker:any) => ({
          ...worker,
          emergencyStopped: false
        }))
      }));
      setLoading(false);
      logAuditData("Emergency stop lifted successfully","AI Bot started")
    } catch (err) {
      setLoading(false);
      handleShowToast(
        (err as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleIndividualWorkerStartBot= async (id:number) => {
    try {
      console.log(id)
      setIndividualBotId(id)
      await handleIndividualWorkerBot({ newState: false, workerNumber:id });
      handleShowToast("Worker started successfully!", ToastType.success);
      
      const updatedWorkers = workersDetail?.workers?.map((worker:any, index:number) =>
        index === id - 1
          ? { ...worker, emergencyStopped: false }
          : worker 
      );
      dispatch(setWorkerList({ ...workersDetail, workers: updatedWorkers }));
      setIndividualBotId(0)
    } catch (err) {
      handleShowToast(
        (err as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
      setIndividualBotId(0)
    }
  };

  const handleStopIndividualBot = async () => {
    try {
      setIndividualLoading(true);
      await handleIndividualWorkerBot({ newState: true ,workerNumber:individualBotId});
      handleModal();
      handleShowToast("Worker stopped successfully!", ToastType.success);
      const updatedWorkers = workersDetail?.workers?.map((worker:any, index:number) =>
        index === individualBotId - 1
      ? { ...worker, emergencyStopped: true }
      : worker 
    );
    console.log("updatedWorkers in stop",updatedWorkers,workersDetail)

      dispatch(setWorkerList({ ...workersDetail, workers: updatedWorkers }));
      setIndividualLoading(false);
      setIndividualBotId(0)
    } catch (err) {
      setIndividualLoading(false);
      handleShowToast(
        (err as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
      setIndividualBotId(0)
    }
  };


  const handleTable = useCallback(() => {
    const displaydata = workersDetail?.workers?.map((data: any,index:number) => {
      return {
        host: data?.host,
        status: data?.status,
        peakConcurrentSTT: data?.peakConcurrentSTT,
        activeCalls: data?.activeCalls,
        inQueue: data?.inQueue,
        callsPendingIntro: data?.callsPendingIntro,
        humanStreamEstablished: `${data?.humanStreamEstablished}`,
        button: data.emergencyStopped ? (
            <Button
              prefix={<Check />}
              className="animated green-light"
              label="Start"
              action={()=>
                {setIndividualBotId(index+1)
                  handleIndividualWorkerStartBot(index+1)}}
              disabled={index+1 ===individualBotId }
              clicked={index+1 === individualBotId}
            ></Button>
          ) : (
            
            <Button
              prefix={<Close />}
              className="animated red-light"
              label="Stop"
              action={()=>{
                setIndividualBotId(index+1)
                handleModal()}}
              ></Button>
             
          )

        
      };
    });
    setTableData(displaydata ?? []);
  }, [workersDetail,individualBotId,individualLoading]);

  useEffect(() => {
    handleTable();
  }, [workersDetail,individualBotId,individualLoading]);

  useEffect(() => {
    dispatch(fetchWorkersList({}));
    dispatch(fetchZoneList({}))
  }, []);

  useEffect(()=>{
    if(!showModal){
      setIndividualBotId(0)
    }

  },[showModal])

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "AI Bot calling viewed",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
           await sendActivityLog({
            logType: "AI Bot calling viewed",
            jsonData: result,
          });
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
    logAuditData();
  }, [profileInfo]);

  return (
    <div className={` ${styles.aiBoat}`}>
        <HeroSection title="AI Bot Calling" />
      {workersDetailLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <>
         <div className={`flex justifyEnd gap-10`}>
         <Button
             
              className="animated green-light"
              label="Refresh"
              action={()=> window.location.reload()}
            ></Button>
          {workersDetail.emergencyStopped ? (
            <Button
              prefix={<Check />}
              className="animated green-light"
              label="Start"
              action={handleStartBot}
              disabled={loading}
              clicked={loading}
            ></Button>
          ) : (
            <Tooltip title={!profileInfo?.permissions?.includes(PermissionListEnum.STOP_BOT_WORKER) ? "You don't have permission to access this." : ''}>
             <div>
            <Button
              prefix={<Close />}
              className="animated red-light"
              label="Stop"
              action={handleModal}
              disabled={!profileInfo?.permissions?.includes(PermissionListEnum.STOP_BOT_WORKER)}
              ></Button>
              </div>
            </Tooltip>
          )}
           <Button
              className="animated primary"
              label="Zone"
              action={handleZoneModal}
            ></Button>
          </div>
          <div className={` ${styles.aiBoat__table}`}>
          <CustomTable
            rows={tableData}
            columns={columns}
            search={""}
            tableStriped
            hasMore={tableData.length < 2}
          ></CustomTable>
            </div>
          <ConfirmationModal
            isOpen={showModal}
            setIsOpen={setShowModal}
            buttonComponent={
              <Button
                label={"Yes, Stop"}
                className="red xl full"
                action={individualBotId ?  handleStopIndividualBot:handleStopBot }
                disabled={individualBotId ? individualLoading :loading}
                clicked={individualBotId ? individualLoading :loading}
              />
            }
          >
            <h2>{individualBotId ? "Stop Worker" :"Stop Bot"}</h2>
            <p>
              Are you sure you want to stop {individualBotId? "worker" :"bot"}.
              <br />
              This action cannot be undone.
            </p>
          </ConfirmationModal>

          <ZoneModal open={showZoneModal} handleClose={handleZoneModal}/>
        </>
      )}
    </div>
  );
};

export default AIBotCalling;
