import * as yup from "yup";

export const refundSchema = (maxAmount:any) => yup.object({
  refundMethod: yup.string().required("Refund method is required"),
  amount: yup.lazy((value, context) => {
    const { refundMethod } = context.parent;
    if (refundMethod === 'full') {
      return yup.number().nullable().notRequired();
    } else {
      return yup.number()
      .moreThan(0, "Amount must be greater than 0.")
        .transform((value, originalValue) => {
          return originalValue === "" || isNaN(originalValue) ? null : Number(originalValue);
        })
        .max(Number(maxAmount.toFixed(2)), `Amount must be at most ${maxAmount.toFixed(2)}`)
        .required("Amount is required");
    }
  }),
});

export const FreetrialSchema =  yup.object({
  days: yup
  .string()
  .nullable()
  .transform((value, originalValue) => (originalValue === "" ? null : value)) 
  .test(
    "is-valid-number",
    "Days should be between 1 and 60",
    (value) => {
      if (value === null) return true; 
      const num = Number(value);
      return !isNaN(num) && num >= 1 && num <= 60; 
    }
  )
  .required("Days are required"),
});